.header-container {
    display: flex;
    flex-direction: row;
    height: 72px;
    border-color: white;
    border-width: 2px;
    justify-content: space-between;
}

.main-header {
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
}

.sub-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.title-container {
    height: 32px;
    flex-grow: 1;
    margin-top: 8px;
}

.tab-container {
    display: flex;
    flex-direction: row;
}

.tab-item, .tab-item-active {
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 10px;
    margin-right: 24px;
    cursor: pointer;
}

.search-bar-text {
    font-size: 14px;
    font-weight: 500;
    color: #a2a0a2;
}


.tab-item:hover, .tab-item-active:hover {
    color: white;
    border-color: white;
}

.tab-item {
    color: #a2a0a2;
}

.tab-item-active {
    color: #6296f1;
    border-width: 0px;
    border-bottom: 2px;
    border-style: solid;
}

.side-header {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.profile-icon, .profile-icon-small {
    background-color: #ffd100;
    justify-content: center;
    text-align: center;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    margin-right: 16px;
}

.profile-icon-small {
    width: 28px;
    height: 28px;
    border-radius: 14px;
    line-height: 28px;
    margin-right: 24px;
}

h1 {
    font-size: 20px;
    font-weight: 500;
    color: white;
    margin: 0;
}

.small-font {
    font-size: 12px;
    margin: 0px;
}

.plan-container {
    display: flex;
    flex-direction: column;
    background-color: #2a3c68;
    width: 130px;
    height: 59px;
    border-radius: 8px;
    margin-right: 12px;
}

.plan-text-container {
    margin:8px;
    margin-right: 12px;
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-around;
}

.plan-text-1, .plan-text-2 {
    font-size: 13.7px;
    margin: 0;
}

.plan-text-1 {
    color: white;
}

.plan-text-2 {
    color: #6296f1;
}

.plan-text-2:hover {
    text-decoration: underline;
    cursor: pointer;
}

.add-icon {
    width: 16px;
    height: 16px;
    fill: white;
    margin: 6px;
}

.add-icon-container {
    width: 28px;
    height: 28px;
    border-radius: 14px;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    margin-right: 12px;
    background-image: linear-gradient(to right top, rgb(255, 82, 99) 0%, rgb(255, 115, 129) 35%, rgb(252, 189, 1) 100%);
}

.add-icon-container:hover {
    cursor: pointer;
}

.search-bar-container {
    width: 140px;
    height: 32px;
    border-width: 1px;
    border-radius: 16px;
    border-color: #424244;
    border-style: solid;
    margin-right: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.search-icon {
    fill: #a2a0a2;
    width: 14px;
    height: 14px;
    margin-left: 10px;
    margin-right: 10px;
}