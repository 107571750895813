
.secondary-header-container {
    height: 41px;
    border-width: 0px;
    border-top: 1px;
    border-bottom: 1px;
    border-style: solid;
    color: #424244;
    display: flex;
    flex-direction: row;
    padding-left: 24px;
    padding-right: 24px;
}

.secondary-header-text {
    color: #a2a0a2;
    font-size: 12px;
}