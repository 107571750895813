.sidebar-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.header {
    height: 72px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 26px;
    padding-right: 24px;
}

.asana-icon {
    width: 80px;
}

.sidebar-toggle-icon {
    fill: #a2a0a2;
    width: 25px;
}

.navigation-button-icon {
    fill: #a2a0a2;
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.secondary-navigation-button-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.navigation-button-container {
    display: flex;
    flex-direction: row;
    height: 32px;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
}

.navigation-button-text, .secondary-navigation-button-text, .secondary-navigation-button-text-footer {
    margin: 0;
    color: white;
    font-size: 14px;
    font-weight: 400;
}

.secondary-navigation-button-container, .secondary-navigation-button-container-footer {
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-width: 0;
    border-top: 1px;
    border-style: solid;
    border-color: #424244;
    padding-left: 24px;
}

.secondary-navigation-button-text {
    color: #a2a0a2;
}
.secondary-navigation-button-text-footer {
    color: white;
}

.navigation-container {
    margin-bottom: 16px;
}

.asana-icon:hover {
    cursor: pointer;
}

.sidebar-toggle-icon:hover {
    fill: white;
    cursor: pointer;
}

.secondary-navigation-button-container:hover {
    cursor: pointer;
}

.navigation-button-container:hover, .secondary-navigation-button-container-footer:hover {
    background-color: #4c4d4f;
    cursor: pointer;
}

.secondary-navigation-button-container:hover .secondary-navigation-button-text {
    color: white;
}