.column-container {
    display: flex;
    flex-direction: column;
    width: 280px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 24px;
}

.empty-tasks-container {
    background-image: linear-gradient(#2a2b2d, transparent);
    border-radius: 8px;
    height: 100%;
}

h3 {
    font-size: 16px;
    font-weight: 500;
    color: white;
}

.add-task-container, .add-task-container-empty-column {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 6px;
    margin-top: 8px;
    height: 40px;
}

.add-task-container-empty-column {
    margin-left: 12px;
    margin-right: 12px;
}


.add-task-container:hover, .add-task-container-empty-column:hover {
    background-color: #323335;
    cursor: pointer;
}

.add-task-text {
    color: #a2a0a2;
    font-size: 14px;
}

.add-task-icon {
    width: 12px;
    margin-right: 4px;
    fill: #a2a0a2;
}