.task-container {
    display: flex;
    flex-direction: column;
    background-color: #2a2b2d;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    color: #424244;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 18px;
}

.task-container:hover {
    cursor: pointer;
    border-color: #a2a0a2;
}

.task-title-container {
    display: flex;
    flex-direction: row;
}

.task-icon {
    width: 19px;
    fill: #a2a0a2;
    margin-right: 6px;
}

.task-text {
    color: white;
    font-size: 16px;
}

.task-date-container {
    padding-left: 4px;
    margin-bottom: 16px;
}

.task-date {
    color: #a2a0a2;
    font-size: 12px;
    margin: 0;
}

.task-project-pill {
    background-color: #414244;
    height: 6px;
    width: 42px;
    border-radius: 3px;
}