.app {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: #181818;

}

.sidebar {
  width: 240px;
  background-color: #2a2b2d;
}

.homepage {
  flex: 1;
  background-color: #1e1f21;
}